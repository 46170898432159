import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';

function FormPopUp(props) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const payload = { email, password, roleId: 6 }; // Define the request payload
      const response = await axios.post(
        'https://www.astsoftwaresolutions.in/api/login',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Handle successful response
        const { token, user } = response.data;
        setSuccessMessage('Login successful!');
        setErrorMessage('');

        // Store the token and user details in session storage
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('user', JSON.stringify(user));

        handleClose(); // Close the modal
        
        // Refresh the page
        window.location.reload();
      } else {
        setErrorMessage('Login failed. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred');
      } else {
        setErrorMessage('Network error. Please try again later.');
      }
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <Button className={props.className} onClick={handleShow}>
        {props.name}
      </Button>

      <Modal
        className="loginModal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4 p-sm-4 p-md-5">
          <h4>Login</h4>
          <Button
            variant="close position-absolute top-20 right-20"
            onClick={handleClose}
          ></Button>

          <form
            className="needs-validation row g-3"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="col-12">
              <div className="form-group form-control-email">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group form-control-password">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <a
                  className="viewpassword"
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    togglePasswordVisibility();
                  }}
                >
                  Show
                </a>
              </div>
            </div>
            {errorMessage && (
              <div className="col-12 text-danger">{errorMessage}</div>
            )}
            {successMessage && (
              <div className="col-12 text-success">{successMessage}</div>
            )}
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" className="btn-login">
                <span>Login</span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FormPopUp;
