import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // React Router for navigation
import logoImg from '../images/main-logo.png';

import Navigation from './Navigation.js';
import FormPopUp from './FormPopUp.js';
import FormRegisterPopUp from './FormRegisterPopUp.js';

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // React Router navigation hook

  // Check login state on component mount
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    setIsLoggedIn(!!token); // If token exists, user is logged in
  }, []);

  // Logout function
  const handleLogout = () => {
    sessionStorage.clear(); // Clear all session data
    setIsLoggedIn(false); // Update state
    navigate('/'); // Navigate to home after logout
  };

  // Navigate to profile page
  const goToProfile = () => {
    navigate('/profile'); // Navigate to profile route
  };

  return (
    <>
      <header className="position-static">
        <div className="container-fluid headerTop py-1 px-sm-3">
          <div className="w-1200 mx-auto" data-aos="fade-top" data-aos-delay="200">
            <nav className="navbar navbar-expand-xl pe-0">
              <div className="w-100">
                <div className="row g-3 align-items-center">
                  <div className="col-auto" data-aos="fade-right" data-aos-delay="200">
                    <Link className="navbar-brand" to="/">
                      <img src={logoImg} alt="univariety" width={200} className="img-fluid" />
                    </Link>
                  </div>

                  <div className="col-xl-auto col-md col d-none d-sm-flex justify-content-end order-xl-1">
                    {isLoggedIn ? (
                      // Show Profile and Logout buttons if logged in
                      <>
                        <button
                          className="btn btn-register me-2 border-0"
                          onClick={goToProfile}
                        >
                          <span>Profile</span>
                        </button>
                        <button
                          className="btn btn-login me-2 border-0"
                          onClick={handleLogout}
                        >
                          <span>Logout</span>
                        </button>
                      </>
                    ) : (
                      // Show Login and Register buttons if not logged in
                      <>
                        <FormPopUp className="btn btn-login me-2 border-0" name="Login" />
                        <FormRegisterPopUp className="btn btn-register border-0" name="Register" />
                      </>
                    )}
                  </div>

                  <Navigation />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
