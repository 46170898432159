import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Import CSS and Bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './css/univariety.css';
import './css/success-style.css';

// Import Components
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home/Home';
import ProfilePage from './Component/ProfilePage/ProfilePage';

function App() {
  return (
    <>
      <Header />
      <main className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<ProfilePage />} />
          {/* Add other routes as needed */}
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
